import {
  ContactType,
  DocumentType,
  PriceType,
  ProduitType,
  ReservationType,
} from '@innedit/innedit-type';
import {
  ContactData,
  PriceData,
  ProduitData,
  ReservationData,
} from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ListItemProps } from '~/containers/Espace/List/props';
import displayCurrency from '~/utils/displayCurrency';

import Item from './index';

const ListItemReservation: FC<
  ListItemProps<ReservationType, ReservationData>
> = ({ docId, index, onClick, model }) => {
  const [doc, setDoc] = useState<DocumentType<ReservationType>>();
  const [product, setProduct] = useState<DocumentType<ProduitType>>();
  const [contact, setContact] = useState<DocumentType<ContactType>>();
  const [price, setPrice] = useState<DocumentType<PriceType>>();

  useEffect(() => {
    const isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);

        if (document) {
          // on récupére le produit, le contact et le prix
          if (document.productId) {
            const productModel = new ProduitData({
              espaceId: document.espaceId,
            });
            productModel
              .findById(document.productId)
              .then(snapshot => {
                if (isMounted) {
                  setProduct(snapshot);
                }

                return isMounted;
              })
              .catch(toast.error);
          }

          if (!document.isCustomPrice && document.priceId) {
            const priceModel = new PriceData({
              espaceId: document.espaceId,
            });
            priceModel
              .findById(document.priceId)
              .then(snapshot => {
                if (isMounted) {
                  setPrice(snapshot);
                }

                return isMounted;
              })
              .catch(toast.error);
          }

          if (document.contactId) {
            const contactModel = new ContactData({
              espaceId: document.espaceId,
            });
            contactModel
              .findById(document.contactId)
              .then(snapshot => {
                if (isMounted) {
                  setContact(snapshot);
                }

                return isMounted;
              })
              .catch(toast.error);
          }
        }
      }
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  let displayPrice = price
    ? `${price.amount} ${displayCurrency(price.currency)}`
    : undefined;

  if (doc.customPrice) {
    displayPrice = `${doc.customPrice} ${displayCurrency('EUR')}`;
  }

  return (
    <Item
      date={doc.createdAt}
      description={`${contact ? contact.label : ''} ⇾ ${
        product ? product.label : ''
      }`}
      doc={doc}
      index={index}
      label={`${doc.quantity} x ${displayPrice}`}
      onClick={onClick}
    />
  );
};

export default ListItemReservation;
