import { ActionProps } from '~/components/Actions/props';
import IconCalendar from '~/icons/Calendar';
import IconChartTreeMap from '~/icons/ChartTreeMap';
import IconPercentage from '~/icons/Percentage';

const useProduits = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/produits${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/produits${to}`.length) ===
      `/espaces/${espaceId}/produits${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconChartTreeMap,
      label: 'Produits',
      to: `/espaces/${espaceId}/produits`,
    },
    {
      className: isActive(`/reservations`),
      icon: IconCalendar,
      label: 'Réservations',
      to: `/espaces/${espaceId}/produits/reservations`,
    },
    {
      className: isActive(`/discounts`),
      icon: IconPercentage,
      label: 'Réductions',
      to: `/espaces/${espaceId}/produits/discounts`,
    },
  ];
};

export default useProduits;
